import '../scss/calc.scss';

/**
 * RentCalculator class handles the calculation and display of rent-related values.
 */
class RentCalculator {
    /**
     * @param {Array} zipCodes - Array of available zip codes.
     * @param {Array} voucherSizes - Array of voucher sizes and their corresponding data.
     */
    constructor(zipCodes, voucherSizes) {
        // Cache the DOM elements
        this.contractRentInput = document.getElementById('contract-rent');
        this.utilityAllowanceInput = document.getElementById('utility-allowance');
        this.paymentStandardInput = document.getElementById('payment-standard');
        this.monthlyIncomeInput = document.getElementById('monthly-income');
        this.calculateRentButton = document.getElementById('calculate-rent');
        this.voucherSizeSelect = document.getElementById('voucher-size');
        this.zipCodeSelect = document.getElementById('zip-code');
        this.warningMessagesDIV = document.getElementById('warning-messages');
        this.popup = document.getElementById('kcdc-result-calculator-popup');
        this.popupCloseButton = document.getElementById('close-button');
        this.popupInfos = document.querySelectorAll('.kcdc-calculator-info');

        this.popupCloseButton.addEventListener('click', () => {
            this.popup.style.display = 'none';
        } );


        // Deduction inputs
        this.childrenInput = document.getElementById('children');
        this.disabledInput = document.getElementById('disabled');
        this.elderlyCheckbox = document.getElementById('elderly');
        this.medicalCostsInput = document.getElementById('medical-costs');
        this.childcareExpensesInput = document.getElementById('childcare-expenses');

        // Result elements
        this.grossRentOutput = document.getElementById('gross-rent');
       this.paymentStandard = document.getElementById('lower-gross-rent');
        this.maxContractRentOutput = document.getElementById('max-contract-rent');
 
        
        this.housingAuthorityRentOutput = document.getElementById('housing-authority-rent');
        this.tenantRentOutput = document.getElementById('tenant-rent');
        this.maxFamilyRentOutput = document.getElementById('max-family-rent');
        this.resultsContainer = document.getElementById('results');
        this.userStatement = document.getElementById('user-statement');

        // Data passed from PHP
        this.zipCodes = zipCodes;
        this.voucherSizes = voucherSizes;
        this.zipCodeMap = this.createZipCodeMap(voucherSizes);

        // Initialize
        this.paymentStandardInput.readOnly = true; // Make the payment standard input read-only
        this.populateZipCodes();

        // Event listeners
        
        this.calculateRentButton.addEventListener('click', () => this.calculateRent());

        this.listenForButtonOpen();
    }
    
    listenForButtonOpen() { 
        
        
        this.zipCodeSelect.addEventListener('change', () => this.updatePaymentStandard());
        this.voucherSizeSelect.addEventListener('change', () => this.updatePaymentStandard());
       this.contractRentInput.addEventListener('input', () => this.checkFields());
       this.utilityAllowanceInput.addEventListener('input', () => this.checkFields());
       this.monthlyIncomeInput.addEventListener('input', () => this.checkFields());
    }

    checkFields() {
        const zipCode = this.zipCodeSelect.value.trim();
        const voucherIndex = this.voucherSizeSelect.value.trim();
        const contractRent = this.contractRentInput.value.trim();
        const utilityAllowance = this.utilityAllowanceInput.value.trim();
        const monthlyIncome = this.monthlyIncomeInput.value.trim();

        // reset the warning messages
        this.warningMessagesDIV.innerHTML = '';

        const showMessages = ({ message, element}) => { 
            let id = `${element.id}-error`;
            let messageElement = document.createElement('p');
            messageElement.id = id;
            messageElement.classList.add('error-message');
            messageElement.textContent = message;

            this.warningMessagesDIV.appendChild(messageElement);
        }

        const hideMessages = ({ element }) => {
            let id = `${element.id}-error`;
            let messageElement = document.getElementById(id);
            if (messageElement) {
                messageElement.remove();
            }
        }

        
        let allValid;
       let  items_to_check = [
            {
                value:  zipCode,
                message: "Please select a zip code",
                element:  this.zipCodeSelect
            },
            {
                value:  voucherIndex,
                message: "Please select a voucher size",
                element:  this.voucherSizeSelect
            },
            {
                value:  contractRent,
                message: "Please enter the contract rent",
                element: this.contractRentInput
            },
            {
                value:  utilityAllowance,
                message: "Please enter the utility allowance",
                element: this.utilityAllowanceInput
            },
            {
                value:  monthlyIncome,
                message: "Please enter your monthly income",
                element: this.monthlyIncomeInput
            }
        ];
        let empty_values = [];

        // Iterate over each item and check if it is empty
        items_to_check.forEach(item => {
            if (!item.value || item.value.trim() === '') {
                empty_values.push(item);
                // Optionally, display the error message
                showMessages(item);
            } else {
                // Clear the error message if the field is correctly filled
                hideMessages(item);
            }
        });
        
        // Determine if all fields are valid
         allValid = empty_values.length === 0;
        
        // Show or hide the "Calculate Rent" button based on whether all fields are filled
        if (allValid) {
            this.calculateRentButton.style.display = 'block';
        } else {
            this.calculateRentButton.style.display = 'none';
        }
        

         
    }


    /**
     * Create a map from zip codes to their voucher sizes.
     * @param {Array} data - Array of voucher sizes and their corresponding data.
     * @returns {Object} - Map of zip codes to their voucher sizes.
     */
    createZipCodeMap(data) {
        return data.reduce((map, item) => {
            map[item.zipCode] = item.Voucher_Sizes;
            return map;
        }, {});
    }

    /**
     * Populate the zip code dropdown with options.
     */
    populateZipCodes() {
        this.zipCodes.forEach(zipCode => {
            let option = document.createElement('option');
            option.value = zipCode;
            option.textContent = zipCode;
            this.zipCodeSelect.appendChild(option);
        });

        // make  37915 the default zip code
        this.zipCodeSelect.value = '37915';
    }

    /**
     * Update the payment standard based on the selected zip code and voucher size.
     */
    updatePaymentStandard() {
        const zipCode = this.zipCodeSelect.value;
        const voucherIndex = this.voucherSizeSelect.value;

        if (zipCode && voucherIndex !== '') {
            const voucherSizes = this.zipCodeMap[zipCode];

            if (voucherSizes) {
                const size = voucherSizes[voucherIndex];
                this.paymentStandardInput.value = size ? size.toFixed(2) : ''; // Ensure value is displayed to 2 decimal places
            }
        } else {
            this.paymentStandardInput.value = '';
        }

        this.checkFields();
    }

    /**
     * Calculate rent based on user inputs and display the results.
     */
    calculateRent() {
        // Get input values
        const contractRent = parseFloat(this.contractRentInput.value) || 0;
        const utilityAllowance = parseFloat(this.utilityAllowanceInput.value) || 0;
        const paymentStandard = parseFloat(this.paymentStandardInput.value) || 0;
        const monthlyGrossIncome = parseFloat(this.monthlyIncomeInput.value) || 0;
        const children = parseInt(this.childrenInput.value) || 0;
        const disabled = this.disabledInput.checked;
        const isElderly = this.elderlyCheckbox.checked;
        const medicalCosts = parseFloat(this.medicalCostsInput.value) || 0;
        const childcareExpenses = parseFloat(this.childcareExpensesInput.value) || 0;

        const hideAllPopupInfos = () => {
            this.popupInfos.forEach(info => {
                info.style.display = 'none';
            });
        }


        
       
        // Calculate Adjusted Monthly Income
        let adjustedIncome = monthlyGrossIncome;
        // round adjustedIncome up 
       
        adjustedIncome -= (children * 40); // $40 per child
        if (disabled) {
            adjustedIncome -= 33; // $33 for disabled family member
        }
        if (isElderly) {
            adjustedIncome -= 33; // 33 for elderly head of household
        }
        if (medicalCosts > 0) {
            adjustedIncome -= medicalCosts * 0.03; // 3% of medical costs;
        }
        if (childcareExpenses > 0) {
            adjustedIncome -= childcareExpenses;
        }

        // if adjustIncome is less than zero, set it to zero
        adjustedIncome = Math.max(adjustedIncome, 0);
        

        console.log('Adjusted Income: ', adjustedIncome);
        

        let trueHousingRent;
        let trueTenant;
        let maxFamilyRent;
        let phaSubsidyAmount;
        // Calculate the other values
        let  grossRent = contractRent + utilityAllowance;
        

        let isGrossHigherThanPaymentStandard = grossRent > paymentStandard;
        
        
        let minimum_payment = Math.ceil(adjustedIncome * 0.30);
        let maximum_payment = Math.floor(adjustedIncome * 0.40);
        phaSubsidyAmount = paymentStandard - minimum_payment;
        trueTenant = contractRent - phaSubsidyAmount;
        let maximumSubsidy;
       
        let monthlyPayment = trueTenant + utilityAllowance;  
        
    
 
        
        
        const secondMaxContractCalculator = () => { 
            let ttp = minimum_payment;  
             // if ttp is less than 50 then set it to 50
             if (ttp < 50) {
                ttp = 50;
            }
            let familyShare = maximum_payment;
              maximumSubsidy = paymentStandard - ttp;
            if (trueTenant < 0) {
                let difference = Math.abs(trueTenant);
                trueTenant = 0;
            }

            // if family share is less than ttp set it to ttp
            if (familyShare < ttp) {
                familyShare = ttp;
            }


           
            
            let rent = maximumSubsidy + familyShare - utilityAllowance;
            
            trueHousingRent = contractRent - trueTenant;
 
          
            
            return maximumSubsidy + familyShare - utilityAllowance;
        
        }
          
        // gross rent   


    
        const overContractRent = maximum_payment - monthlyPayment < 0
        let message;
        const IsRentBurdenTooHigh = () => {
            let ttp = minimum_payment;
            // if ttp is less than 50 then set it to 50
            if (ttp < 50) {
                ttp = 50;
            }
            let maximumSub = paymentStandard - ttp;
            let housingAuthority = Math.min(maximumSub, contractRent);

            
            let utitltyReimbursement = maximumSub - housingAuthority;
            if (trueTenant < 0) {
                let difference = Math.abs(trueTenant);
                trueTenant = 0;
            }

            // expenses = tenant rent + utility allowance - utility reimbursement
            let expenses = trueTenant + utilityAllowance - utitltyReimbursement;

            let rentBurden = expenses / adjustedIncome;
            // fixed to 2 decimal places
            
           
            // if rent burden is greater than .40 then return false
            if (rentBurden > 0.40) {
                return true;
            } else {
                return false;

            }
        }
        
        
         
        
 

    
    
        
        let maxContractRentAllowed;
        if (adjustedIncome == 0) {
      
            // scenaro 1 income zero
            maxContractRentAllowed = paymentStandard - utilityAllowance;

            this.popup.style.display = 'flex';
            // clear all popup infos
            hideAllPopupInfos();

       
            grossRent = grossRent - utilityAllowance;
            let grossRentMoreThanMaxContractRent = (grossRent) > maxContractRentAllowed;
            
              
 
           
            
            if (grossRentMoreThanMaxContractRent ) {

            this.userStatement.style.display = 'block';

                message = ` Uh-oh! Looks like your rent goes above the payment standard 😬 . Your max contract rent should be $${Math.floor(secondMaxContractCalculator())}, so you might want to renegotiate or look for a lower price!`
            
            } else {
                 // display max contract rent allowed ,gross rent and tell user this is the max rent allowed
            this.maxContractRentOutput.querySelector('strong').textContent = `$${Math.floor(maxContractRentAllowed)}`;
            this.grossRentOutput.querySelector('strong').textContent = `$${Math.floor(grossRent)}`;
           this.paymentStandard.querySelector('strong').textContent = `$${Math.floor(paymentStandard)}`;

            this.maxContractRentOutput.style.display = 'block';
            // this.grossRentOutput.style.display = 'block';
        //    this.paymentStandard.style.display = 'block';
            this.userStatement.style.display = 'block';
                message = `Congrats 🎉 ! You’re within the payment standard, with a max contract rent of $${Math.floor(maxContractRentAllowed)}. Looks like you're good to go!`;
            }
            
            this.userStatement.querySelector('p').textContent = message;
            


        } else   if (isGrossHigherThanPaymentStandard ) { 
         
              
 
            
          

            if (!overContractRent && !IsRentBurdenTooHigh()) {

             

                // scenario 2 gross rent is higher than payment standard
                console.log('scenario 2');
                
                    
            
                maxFamilyRent = maximum_payment;

                this.popup.style.display = 'flex';

                // clear all popup infos
                hideAllPopupInfos();

                // display max contract rent allowed ,gross rent and tell user this is the max rent allowed

                this.maxContractRentOutput.querySelector('strong').textContent = `$${Math.floor( secondMaxContractCalculator())}`;

                this.grossRentOutput.querySelector('strong').textContent = `$${Math.floor(grossRent)}`;

               this.paymentStandard.querySelector('strong').textContent = `$${Math.floor( paymentStandard)}`;

                this.housingAuthorityRentOutput.querySelector('strong').textContent = `$${Math.floor(trueHousingRent)}`;

                this.tenantRentOutput.querySelector('strong').textContent = `$${Math.floor(trueTenant)}`;

                // this.maxFamilyRentOutput.querySelector('strong').textContent = `$${maxFamilyRent}`;

                this.maxContractRentOutput.style.display = 'block';
                // this.grossRentOutput.style.display = 'block';
            //    this.paymentStandard.style.display = 'block';
                this.housingAuthorityRentOutput.style.display = 'block';
                this.tenantRentOutput.style.display = 'block';
                // this.maxFamilyRentOutput.style.display = 'block';

                this.userStatement.style.display = 'none';
            } else {
                hideAllPopupInfos();
                this.popup.style.display = 'flex';
                this.userStatement.style.display = 'block';
                this.userStatement.querySelector('p').textContent = ` Uh-oh! Looks like your rent goes above the payment standard 😬. 
                 Your gross rent is $${Math.floor(grossRent)} and your max contract rent should be $${Math.floor( secondMaxContractCalculator())}, so you might want to renegotiate or look for a lower price!`;
                
                    };

               
        } else {

  
             
              
           
            
            // scenario 3 gross rent is lower than payment standard

            console.log('scenario 3');
            
          let thirtyPercentIncome = Math.floor(0.30 * adjustedIncome);
            
                // Housing Authority Rent Calculation
                let adjustedHousingPortion = contractRent - thirtyPercentIncome;
                let housingAuthorityRent = Math.max(adjustedHousingPortion, 0);
                let tenantRent = contractRent - housingAuthorityRent;
                let leftOverRent = +contractRent - (Math.floor(+housingAuthorityRent) + Math.floor(+tenantRent));
                trueHousingRent = housingAuthorityRent + utilityAllowance + leftOverRent;
                trueTenant = tenantRent - utilityAllowance - leftOverRent;
            maxFamilyRent = Math.floor(0.40 * adjustedIncome);
            
            this.popup.style.display = 'flex';

            // clear all popup infos
            hideAllPopupInfos();

            // display max contract rent allowed ,gross rent and tell user this is the max rent allowed

            this.maxContractRentOutput.querySelector('strong').textContent = `$${ secondMaxContractCalculator()}`;
             
            this.grossRentOutput.querySelector('strong').textContent = `$${Math.floor(grossRent)}`;

           this.paymentStandard.querySelector('strong').textContent = `$${Math.floor(paymentStandard)}`;

            this.housingAuthorityRentOutput.querySelector('strong').textContent = `$${Math.floor(trueHousingRent)}`;

            this.tenantRentOutput.querySelector('strong').textContent = `$${Math.floor(trueTenant)}`;

            // this.maxFamilyRentOutput.querySelector('strong').textContent = `$${maxFamilyRent}`;
// 
            this.maxContractRentOutput.style.display = 'block';

            // this.grossRentOutput.style.display = 'block';

        //    this.paymentStandard.style.display = 'block';

            this.housingAuthorityRentOutput.style.display = 'block';

            this.tenantRentOutput.style.display = 'block';

            // this.maxFamilyRentOutput.style.display = 'block';

            this.userStatement.style.display = 'none';


           
            }
     }
}

// Initialize the RentCalculator with data from PHP

document.addEventListener('DOMContentLoaded', () => {
    const calculator = new RentCalculator(zipCodes, voucherSizes);
});
